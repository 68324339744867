import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import About from "./pages/About";

// Lazy load all components
// const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Pagenotfound = lazy(() => import("./pages/Pagenotfound"));
const Editor = lazy(() => import("./pages/Editor"));
const EditorList = lazy(() => import("./pages/EditorList"));
const EditorImage = lazy(() => import("./pages/EditorWithImage"));
const Plans = lazy(() => import("./pages/Plans"));
const Login = lazy(() => import("./pages/login"));
const Register = lazy(() => import("./pages/register"));

function App() {
  return (
    <>
      <ToastContainer />
      <div>
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/" element={<About />} />
              <Route path="/guide" element={<Contact />} />
              <Route path="/editor/new" element={<Editor />} />
              <Route path="/editor" element={<EditorList />} />
              <Route path="/editor-image" element={<EditorImage />} />
              <Route path="/package" element={<Plans />} />
              <Route path="*" element={<Pagenotfound />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;

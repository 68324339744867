import React from "react";

const UploadFile = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9525 12.2822C19.7214 12.2822 19.4998 12.374 19.3365 12.5374C19.1731 12.7008 19.0813 12.9224 19.0813 13.1535V16.8239C19.0806 17.4677 18.8246 18.0849 18.3693 18.5401C17.9141 18.9953 17.2969 19.2514 16.6532 19.252H5.8273C5.18354 19.2514 4.56634 18.9953 4.11113 18.5401C3.65592 18.0849 3.39988 17.4677 3.39919 16.8239V13.1535C3.39919 12.9224 3.3074 12.7008 3.14402 12.5374C2.98063 12.374 2.75903 12.2822 2.52797 12.2822C2.2969 12.2822 2.0753 12.374 1.91191 12.5374C1.74853 12.7008 1.65674 12.9224 1.65674 13.1535V16.8239C1.65789 17.9297 2.09766 18.9898 2.87954 19.7717C3.66143 20.5536 4.72155 20.9933 5.8273 20.9945H16.6532C17.7589 20.9933 18.8191 20.5536 19.6009 19.7717C20.3828 18.9898 20.8226 17.9297 20.8237 16.8239V13.1535C20.8237 12.9224 20.732 12.7008 20.5686 12.5374C20.4052 12.374 20.1836 12.2822 19.9525 12.2822Z"
          fill="white"
        />
        <path
          d="M6.62931 8.542L10.3695 4.80182V15.7671C10.3695 15.9982 10.4613 16.2198 10.6247 16.3831C10.7881 16.5465 11.0097 16.6383 11.2407 16.6383C11.4718 16.6383 11.6934 16.5465 11.8568 16.3831C12.0202 16.2198 12.1119 15.9982 12.1119 15.7671V4.80182L15.8521 8.542C16.0164 8.7007 16.2365 8.78852 16.4649 8.78653C16.6934 8.78455 16.9119 8.69292 17.0734 8.53139C17.235 8.36985 17.3266 8.15134 17.3286 7.92291C17.3306 7.69447 17.2427 7.4744 17.084 7.31008L11.8567 2.08272C11.6933 1.91939 11.4717 1.82764 11.2407 1.82764C11.0097 1.82764 10.7881 1.91939 10.6248 2.08272L5.3974 7.31008C5.2387 7.4744 5.15088 7.69447 5.15286 7.92291C5.15485 8.15134 5.24648 8.36985 5.40801 8.53139C5.56954 8.69292 5.78806 8.78455 6.01649 8.78653C6.24492 8.78852 6.465 8.7007 6.62931 8.542Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default UploadFile;

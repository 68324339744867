import React from "react";

const MenuIcon = () => {
  return (
    <>
      <svg
        width="17"
        height="13"
        viewBox="0 0 17 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H17V2.16667H0V0ZM0 5.41667H17V7.58333H0V5.41667ZM0 10.8333H17V13H0V10.8333Z"
          fill="#040415"
        />
      </svg>
    </>
  );
};

export default MenuIcon;
